import React from 'react'
import Modal, { Props as ReactModalProps } from 'react-modal'

import styles from './index.module.scss'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'

export default function CheckoutModal({
  isOpen,
  onAfterOpen,
  onRequestClose,
  style,
}: ReactModalProps) {
  const handleClose = (
    event: React.MouseEvent | React.KeyboardEvent,
  ) => {
    onRequestClose && onRequestClose(event)
  }

  return (
    <Modal
      isOpen={isOpen}
      onAfterOpen={onAfterOpen}
      onRequestClose={handleClose}
      style={style}
      className={styles.Modal}
      overlayClassName={styles.Overlay}
    >
      <button className={styles.ModalBtnClose} onClick={handleClose}>
        <CloseIcon />
      </button>
      <div className={styles.ModalImgContainer}>
        <h2
          className={`${styles.InfoContainerTitle} ${styles.InfoContainerTitleMobile}`}
        >
          Will be available soon
        </h2>
      </div>
    </Modal>
  )
}
