import React from 'react'

export interface CheckoutModalState {
  isOpen: boolean
  changeStateIsOpen: (newState: boolean) => void
}

const defaultState: CheckoutModalState = {
  isOpen: false,
  changeStateIsOpen: (newState: boolean) => {},
}

export const CheckoutModalContext =
  React.createContext<CheckoutModalState>(defaultState)
