import React, { useContext } from 'react'
import { Button } from 'components/Button'

import styles from './../index.module.scss'
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg'
import { CartContext } from 'context/CartContext'
import CartItem from './CartItem'

export default function CheckoutLeftBlock({
  isModal,
  titleWrapperClassName,
  itemListClassName,
  checkoutItemsBlockClassName,
}: {
  isModal?: boolean
  titleWrapperClassName?: string
  itemListClassName?: string
  checkoutItemsBlockClassName?: string
}) {
  const { products, addProduct } = useContext(CartContext)

  const handleClear = () => {
    addProduct()
  }

  return (
    <div
      className={`${styles.CheckoutItemsBlock} ${
        checkoutItemsBlockClassName ? checkoutItemsBlockClassName : ''
      }`}
    >
      <div
        className={`${styles.CheckoutActionsWrapper} ${
          titleWrapperClassName ? titleWrapperClassName : ''
        }`}
      >
        <div className={styles.CheckoutTitleWrapper}>
          <h1 className={styles.CheckoutTitle}>My Bag</h1>
          {!isModal && (
            <span className={styles.CheckoutAmount}>
              {products?.length}{' '}
              {products?.length > 1 ? 'items' : 'item'}
            </span>
          )}
        </div>

        {!isModal && (
          <Button
            onClick={handleClear}
            typeView="link"
            className={styles.CheckoutClearBtn}
          >
            <DeleteIcon />
            Clear all
          </Button>
        )}
      </div>

      <div
        className={`${styles.CheckoutCartItemList} ${
          isModal ? styles.CheckoutCartItemHideBg : ''
        } ${itemListClassName ? itemListClassName : ''}`}
      >
        {products?.map((item, index) => {
          return (
            <CartItem item={item} key={item.name} index={index} />
          )
        })}
      </div>
    </div>
  )
}
