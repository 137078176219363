import React from 'react'
import { Link } from 'react-router-dom'

import styles from './index.module.scss'

export const Navigation = ({
  className,
  isMobile,
}: {
  className?: string
  isMobile?: boolean
}) => {
  const handleLinkCLick = () => {
    if (isMobile) {
      document.body.classList.remove('menu-open')
    }
  }

  return (
    <nav>
      <ul className={`${styles.NavList} ${className || ''}`}>
        <li>
          <Link
            to="/collections/?type=image"
            className={styles.NavLink}
            onClick={handleLinkCLick}
          >
            Images
          </Link>
        </li>
        <li>
          <Link
            to="/collections/?type=illustration"
            className={styles.NavLink}
            onClick={handleLinkCLick}
          >
            Illustrations
          </Link>
        </li>
        <li>
          <Link
            to="/collections/?type=vector"
            className={styles.NavLink}
            onClick={handleLinkCLick}
          >
            Vectors
          </Link>
        </li>
        <li>
          <Link
            to="/collections/?type=templates"
            className={styles.NavLink}
            onClick={handleLinkCLick}
          >
            Templates
          </Link>
        </li>
      </ul>
    </nav>
  )
}
