import React, { useContext } from 'react'
import Modal, { Props as ReactModalProps } from 'react-modal'
import { useNavigate } from 'react-router-dom'

import styles from './index.module.scss'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import CheckoutLeftBlock from 'pages/Checkout/components/CheckoutLeftBlock'
import { Button } from '../Button'
import { CheckoutModalContext } from '../../context/CheckoutModalContext'

export default function CartHeaderModal({
  isOpen,
  onAfterOpen,
  onRequestClose,
  style,
}: ReactModalProps) {
  const { isOpen: isOpenCheckout, changeStateIsOpen } = useContext(
    CheckoutModalContext,
  )
  const navigate = useNavigate()
  const handleClose = (
    event: React.MouseEvent | React.KeyboardEvent,
  ) => {
    onRequestClose && onRequestClose(event)
  }

  const handleViewBag = (
    event: React.MouseEvent | React.KeyboardEvent,
  ) => {
    navigate('/checkout')
    onRequestClose && onRequestClose(event)
  }

  const handleCheckoutClick = () => {
    changeStateIsOpen(!isOpenCheckout)
  }

  return (
    <Modal
      isOpen={isOpen}
      onAfterOpen={onAfterOpen}
      onRequestClose={handleClose}
      style={style}
      className={styles.Modal}
      overlayClassName={styles.Overlay}
    >
      <button className={styles.ModalBtnClose} onClick={handleClose}>
        <CloseIcon />
      </button>
      <CheckoutLeftBlock
        isModal={true}
        titleWrapperClassName={styles.CartHeaderModalTitleWrapper}
        itemListClassName={styles.CartHeaderItemList}
        checkoutItemsBlockClassName={
          styles.CartHeaderModalCheckoutItemsBlock
        }
      />
      <Button
        typeView="default"
        className={styles.ModalCheckoutBtn}
        onClick={handleCheckoutClick}
      >
        Checkout
      </Button>
      <Button typeView="outline" onClick={handleViewBag}>
        View Bag
      </Button>
    </Modal>
  )
}
