import React, { useContext, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import styles from './index.module.scss'
import { ReactComponent as LogoIcon } from 'assets/images/logo.svg'
import { ReactComponent as CartIcon } from 'assets/icons/cart.svg'
import { Navigation } from '../Navigation'
import { Button } from '../Button'
import { SearchComponent } from '../SearchComponent'
import { ProfileDropdown } from '../ProfileDropdown'
import { CartContext } from 'context/CartContext'
import MobileNav from './components/MobileNav'
import CartHeaderModal from 'components/CartHeaderModal'

export const Header = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [isOpenCartModal, setIsOpenCartModal] = useState(false)
  const { products } = useContext(CartContext)
  const pathName = location.pathname

  const isMainPage = pathName === '/'
  const isLogined = !!localStorage.getItem('access')

  const handleLoginClick = () => {
    navigate('/login')
  }

  const handleShowModalClick = () => {
    setIsOpenCartModal(true)
  }
  const handleCloseModalRequest = () => {
    setIsOpenCartModal(false)
  }

  return (
    <>
      <header className={styles.HeaderWrapper}>
        <div
          className={`container ${styles.Header} ${
            !isMainPage ? styles.HeaderCollection : ''
          } ${!isLogined ? styles.HeaderNotLogined : ''}`}
        >
          <Link to="/" className={styles.HeaderLogoLink}>
            <LogoIcon />
          </Link>

          {isMainPage ? (
            <>
              <Navigation className={styles.HeaderNav} />
              <MobileNav />
            </>
          ) : (
            <SearchComponent
              className={styles.HeaderSearchComponent}
              searchInputClassName={styles.HeaderSearchInputComponent}
            />
          )}

          {isLogined ? (
            <div className={styles.HeaderAuthorized}>
              <Button
                typeView="outline"
                className={`${styles.HeaderCartBtn} ${
                  products?.length > 0
                    ? styles.HeaderCartBtnRedDot
                    : ''
                }`}
                onClick={handleShowModalClick}
              >
                <CartIcon />
              </Button>
              <ProfileDropdown />
            </div>
          ) : (
            <Button
              typeView="outline"
              className={styles.HeaderBtn}
              onClick={handleLoginClick}
            >
              Log In
            </Button>
          )}
        </div>
      </header>

      {isOpenCartModal && (
        <CartHeaderModal
          isOpen={!!isOpenCartModal}
          onRequestClose={handleCloseModalRequest}
        />
      )}
    </>
  )
}
