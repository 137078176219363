import React from 'react'

import styles from './index.module.scss'
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg'
import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { useWindowSize } from 'hooks/useWindowSize'

interface SearchInputProps {
  search: string
  setSearch: (newState: string) => void
  className?: string
  handleSearch: () => void
}
export const SearchInput = ({
  search,
  setSearch,
  className,
  handleSearch,
}: SearchInputProps) => {
  const { width } = useWindowSize()
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newQuery = e.target.value
    setSearch(newQuery)
  }

  const clearSearch = () => {
    setSearch('')
  }

  return (
    <div className={`${styles.SearchContainer} ${className || ''}`}>
      <form
        onSubmit={(e) => {
          e?.preventDefault()
          handleSearch()
        }}
      >
        <input
          type="text"
          placeholder={
            width > 576
              ? 'Search photos and vectors'
              : 'Search photos'
          }
          value={search}
          onChange={handleInputChange}
          className={styles.SearchInput}
        />
        {search && (
          <button
            type="button"
            onClick={clearSearch}
            className={styles.SearchBtnClear}
          >
            <CloseIcon />
          </button>
        )}
        <button type="submit" className={styles.SearchBtn}>
          <SearchIcon />
        </button>
      </form>
    </div>
  )
}
