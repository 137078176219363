import React, { useEffect, useState } from 'react'
import { CartContext, CartProduct } from './CartContext'

interface Props {
  children: React.ReactNode
}

export const CartContextProvider: React.FunctionComponent<Props> = (
  props: Props,
) => {
  const [products, setProducts] = useState<CartProduct[]>([])

  useEffect(() => {
    const cartInfo = localStorage.getItem('cartInfo')
    if (cartInfo) {
      setProducts(JSON.parse(cartInfo))
    }
  }, [])

  const changeProductState = (product?: CartProduct) => {
    if (product) {
      setProducts([...products, product])
      localStorage.setItem(
        'cartInfo',
        JSON.stringify([...products, product]),
      )
    } else {
      setProducts([])
      localStorage.setItem('cartInfo', JSON.stringify([]))
    }
  }

  const removeProductState = (index: number) => {
    products.splice(index, 1)
    setProducts([...products])
    localStorage.setItem('cartInfo', JSON.stringify(products))
  }

  return (
    <CartContext.Provider
      value={{
        products,
        addProduct: changeProductState,
        removeProduct: removeProductState,
      }}
    >
      {props.children}
    </CartContext.Provider>
  )
}
