import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react'

import styles from './index.module.scss'
import { Navigation } from '../../Navigation'
export default function MobileNav() {
  const ref = useRef(null)
  const [menuIsOpen, setIsOpenMenu] = useState(false)

  useEffect(() => {
    // Function to handle window resize event
    const handleResize = () => {
      if (menuIsOpen && window.innerWidth > 663) {
        document.body.classList.remove('menu-open')
        setIsOpenMenu(false)
      }
    }
    // Add event listener for window resize
    window.addEventListener('resize', handleResize)

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [menuIsOpen])

  const handleMenuIconClick = useCallback(() => {
    if (!menuIsOpen) {
      document.body.classList.add('menu-open')
    } else {
      document.body.classList.remove('menu-open')
    }
    setIsOpenMenu(!menuIsOpen)
  }, [menuIsOpen, setIsOpenMenu])

  return (
    <div
      className={`${styles.HeaderMobileNav} ${
        menuIsOpen && styles.HeaderMobileNavActive
      }`}
    >
      <div
        ref={ref}
        onClick={handleMenuIconClick}
        className={styles.MobileMenuIcon}
      />
      <div
        className={`${styles.MenuNavIcon} ${
          menuIsOpen && styles.MenuNavIconOpen
        }`}
      />

      <div className={`container ${styles.MobileNav}`}>
        <Navigation
          className={styles.MobileNavList}
          isMobile={true}
        />
      </div>
    </div>
  )
}
