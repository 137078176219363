import React from 'react'
import { Link } from 'react-router-dom'

import styles from './index.module.scss'
import { ReactComponent as LogoIcon } from 'assets/images/logo.svg'
import { Navigation } from '../Navigation'
export const Footer = () => {
  return (
    <footer className={styles.FooterWrapper}>
      <div className={`container ${styles.Footer}`}>
        <Link to="/" className={styles.FooterLogoLink}>
          <LogoIcon />
        </Link>

        <Navigation className={styles.FooterNav} />

        <span className={styles.FooterYear}>© 2023</span>
      </div>
    </footer>
  )
}
