import React, { lazy, Suspense, useCallback, useContext } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import ErrorBoundary from './pages/Helpers/ErrorBoundary'
import { Header } from './components/Header'
import { Footer } from './components/Footer'
import { CheckoutModalContext } from './context/CheckoutModalContext'
import CheckoutModal from './components/CheckoutModal'
const PageHome = lazy(() => import('pages/Home'))
const PageSearch = lazy(() => import('pages/Search'))
const PageNotFound = lazy(() => import('pages/NotFound'))
const PageMyOrders = lazy(() => import('pages/MyOrders'))
const PageAuth = lazy(() => import('pages/Auth'))
const PageResetPassword = lazy(
  () => import('pages/Auth/ResetPassword'),
)
const PagePrivacy = lazy(() => import('pages/Privacy'))
const PageOffer = lazy(() => import('pages/Offer'))
const PageCheckout = lazy(() => import('pages/Checkout'))
const PageChangePassword = lazy(
  () => import('pages/Auth/ChangePassword'),
)

const queryClient = new QueryClient()
function App() {
  const { isOpen, changeStateIsOpen } = useContext(
    CheckoutModalContext,
  )

  const handleCloseCartModal = useCallback(() => {
    changeStateIsOpen(false)
  }, [changeStateIsOpen])

  const isLogined = !!localStorage.getItem('access')

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <ErrorBoundary>
            <Header />
            <Suspense fallback={<div>loading</div>}>
              <Routes>
                <Route path="/" element={<PageHome />} />
                <Route path="/collections" element={<PageSearch />} />
                <Route
                  path="/collections/:id"
                  element={<PageSearch />}
                />
                <Route path="/checkout" element={<PageCheckout />} />
                {isLogined && (
                  <>
                    <Route
                      path="/orders"
                      element={<PageMyOrders />}
                    />
                    <Route
                      path="/change-password"
                      element={<PageChangePassword />}
                    />
                  </>
                )}
                <Route
                  path="/login"
                  element={<PageAuth type="login" />}
                />
                <Route
                  path="/reset/new-password"
                  element={<PageResetPassword />}
                />
                <Route
                  path="/sign-up"
                  element={<PageAuth type="registration" />}
                />
                <Route path="/privacy" element={<PagePrivacy />} />
                <Route path="/offer" element={<PageOffer />} />
                <Route path="*" element={<PageNotFound />} />
              </Routes>
            </Suspense>
            <Footer />

            <CheckoutModal
              isOpen={isOpen}
              onRequestClose={handleCloseCartModal}
            />
            <ToastContainer />
          </ErrorBoundary>
        </BrowserRouter>
      </QueryClientProvider>
    </div>
  )
}

export default App
