import React, { useState } from 'react'
import { CheckoutModalContext } from './CheckoutModalContext'

interface Props {
  children: React.ReactNode
}

export const CheckoutModalContextProvider: React.FunctionComponent<
  Props
> = (props: Props) => {
  const [state, setState] = useState<boolean>(false)

  const changeStateIsOpen = (newState: boolean) => {
    setState(newState)
  }

  return (
    <CheckoutModalContext.Provider
      value={{ isOpen: state, changeStateIsOpen }}
    >
      {props.children}
    </CheckoutModalContext.Provider>
  )
}
