import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'

import styles from './index.module.scss'
import { ReactComponent as KeyIcon } from 'assets/icons/key.svg'
import { ReactComponent as FileIcon } from 'assets/icons/file.svg'
import { ReactComponent as LogoutIcon } from 'assets/icons/logout.svg'
import { ReactComponent as ProfileIcon } from 'assets/icons/profile.svg'
import { Button } from '../Button'

export const ProfileDropdown = () => {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef(null)

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      // @ts-ignore
      !dropdownRef.current?.contains(event.target)
    ) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  const handleLogoutClick = () => {
    localStorage.removeItem('access')
    localStorage.removeItem('refresh')
    window.location.reload()
  }

  return (
    <div className={styles.Dropdown} ref={dropdownRef}>
      <div
        className={`${styles.DropdownSelectedOption} ${
          isOpen && styles.DropdownSelectedOptionOpen
        }`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <ProfileIcon />
        Profile
      </div>
      {isOpen && (
        <div className={styles.DropdownOptions}>
          <Link to="/orders" className={styles.DropdownOption}>
            <FileIcon />
            My orders
          </Link>
          <Link
            to="/change-password"
            className={styles.DropdownOption}
          >
            <KeyIcon />
            Change password
          </Link>
          <Button
            typeView="link"
            onClick={handleLogoutClick}
            className={`${styles.DropdownOption} ${styles.DropdownOptionLogout}`}
          >
            <LogoutIcon />
            Log out
          </Button>
        </div>
      )}
    </div>
  )
}
