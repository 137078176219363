import React, { useState, useEffect, useRef, FC } from 'react'

import styles from './index.module.scss'
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg'
import { SearchImgType } from '../SearchComponent'
export interface Option {
  value: SearchImgType | string
  label: React.ReactNode
}

interface DropdownProps<T> {
  value: T | undefined
  options: Option[]
  onSelect?: (option: T) => void
  className?: string
}
export const SearchDropdown: FC<DropdownProps<any>> = ({
  value,
  options,
  onSelect,
  className,
}) => {
  const [selectedOption, setSelectedOption] = useState<Option | null>(
    options?.[0],
  )
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef(null)

  const handleOptionClick = (option: Option) => {
    setSelectedOption(option)
    onSelect && onSelect(option.value as SearchImgType)
    setIsOpen(false)
  }

  useEffect(() => {
    const selectValue = options.find((item) => item.value === value)
    selectValue && setSelectedOption(selectValue)
  }, [value])

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      // @ts-ignore
      !dropdownRef.current?.contains(event.target)
    ) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  return (
    <div
      className={`${styles.Dropdown} ${className || ''}`}
      ref={dropdownRef}
    >
      <div
        className={styles.DropdownSelectedOption}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className={styles.DropdownSelectedLabel}>
          {selectedOption ? selectedOption.label : 'Select an option'}
        </span>
        <span className={isOpen ? styles.DropdownSelectedArrow : ''}>
          <ArrowIcon />
        </span>
      </div>
      {isOpen && (
        <div className={styles.DropdownOptions}>
          {options.map((option) => (
            <div
              key={option.value}
              className={styles.DropdownOption}
              onClick={() => handleOptionClick(option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
