import React, { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import styles from './index.module.scss'
import { SearchInput } from 'components/SearchInput'
import { Option, SearchDropdown } from 'components/SearchDropdown'
import { ReactComponent as ImagesIcon } from 'assets/icons/images.svg'
import { ReactComponent as VectorsIcon } from 'assets/icons/vectors.svg'
import { ReactComponent as TemplatesIcon } from 'assets/icons/templates.svg'

export type SearchImgType =
  | 'image'
  | 'illustration'
  | 'vector'
  | 'templates'

const searchOption = [
  {
    label: (
      <>
        <ImagesIcon />
        Images
      </>
    ),
    value: 'image',
  },
  {
    label: (
      <>
        <VectorsIcon />
        Vectors
      </>
    ),
    value: 'vector',
  },
  {
    label: (
      <>
        <TemplatesIcon />
        Templates
      </>
    ),
    value: 'templates',
  },
] as Option[]

export const SearchComponent = ({
  className,
  searchInputClassName,
  searchDropDownClassName,
}: {
  className?: string
  searchInputClassName?: string
  searchDropDownClassName?: string
}) => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [searchValue, setSearchValue] = useState(
    searchParams?.get('search') || '',
  )
  const [searchImgType, setSearchImgType] = useState<
    SearchImgType | undefined
    // @ts-ignore
  >(searchParams?.get('type') || 'image')

  const handleSearch = () => {
    const search = searchValue ? `search=${searchValue}` : ''
    const imgType = searchImgType
      ? `${searchValue ? '&' : ''}type=${searchImgType}`
      : ''
    navigate(`/collections/?${search}${imgType}`)
  }

  return (
    <div
      className={`${styles.Container} ${className ? className : ''}`}
    >
      <SearchDropdown
        options={searchOption}
        value={searchImgType}
        onSelect={setSearchImgType}
        className={searchDropDownClassName}
      />
      <SearchInput
        search={searchValue}
        setSearch={setSearchValue}
        className={searchInputClassName}
        handleSearch={handleSearch}
      />
    </div>
  )
}
