import React, { PureComponent } from 'react'
import styles from './index.module.scss'

type Props = any
class ErrorBoundary extends PureComponent<Props> {
  constructor(props: any) {
    super(props)
    this.state = {
      hasError: false,
    }
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true }
  }
  componentDidCatch(error: any, info: any) {}

  render() {
    if (this.props.hasError) {
      return (
        <div className="App">
          <img
            src={require('../../../assets/images/logo.svg')}
            alt="Logo"
          />
          <img
            src={require('../../../assets/images/technical_work_error.png')}
            alt="The site is undergoing technical work"
          />
          <h1>
            The site is undergoing <br />
            technical work
          </h1>
        </div>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
