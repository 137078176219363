import React from 'react'
import { License } from '../types'

export interface CartProduct {
  id: string
  license?: License
  name: string
  image: string
  price: string | number
}
export interface CartState {
  products: CartProduct[]
  addProduct: (product?: CartProduct) => void
  removeProduct: (index: number) => void
}

const defaultState: CartState = {
  products: [],
  addProduct: (product?: CartProduct) => {},
  removeProduct: (index: number) => {},
}

export const CartContext =
  React.createContext<CartState>(defaultState)
