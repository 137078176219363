import React, { useContext } from 'react'
import { Button } from 'components/Button'

import styles from './index.module.scss'
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg'
import { CartContext, CartProduct } from 'context/CartContext'

export default function CartItem({
  item,
  index,
}: {
  item: CartProduct
  index: number
}) {
  const { removeProduct } = useContext(CartContext)

  const handleRemoveItem = () => {
    removeProduct(index)
  }

  return (
    <div className={styles.CartItemWrapper}>
      <div className={styles.CartItemImgWrapper}>
        <img src={item.image} alt={item.name} />
      </div>
      <div className={styles.CartItemInfoWrapper}>
        <span className={styles.CartItemTitle}>{item.name}</span>
        <span className={styles.CartItemLicense}>
          {item.license?.name}
        </span>
        <div className={styles.CartItemPriceContainer}>
          <span className={styles.CartItemPrice}>{item.price}</span>
          <Button typeView="link" onClick={handleRemoveItem}>
            <DeleteIcon />
          </Button>
        </div>
      </div>
    </div>
  )
}
